import React from 'react'
import { useState, useEffect } from 'react';

const COUNTDOWN_TARGET = new Date("2024-08-31T23:59:59");

const getTimeLeft = () => {
	const totalTimeLeft = COUNTDOWN_TARGET - new Date();
	const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
	const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
	const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
	const seconds = Math.floor((totalTimeLeft / 1000) % 60);
	return { days, hours, minutes, seconds };
};

const Countdown = () => {
    const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(getTimeLeft());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<div className="flex-row items-center mx-auto container w-10/12">
            <div className="grid justify-items-center md:justify-items-center mb-4">
                <div className="pb-4">
                    <div className="relative rounded-full px-6 py-1 w-fit text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
                    COUNTDOWN CLOSING REGISTRATIONS
                    </div>
                </div>
            </div>

			<div className='w-full border-stone-900 rounded-3xl border-2'>
            <div className="grid grid-cols-4 gap-4 py-12">
				{Object.entries(timeLeft).map((el) => {
					const label = el[0];
					const value = el[1];
					return (
                        
						    <div className='cols-span-1 justify-items-center ' key={label}>
                                <div className='text-center helvetica font-extrabold text-3xl text-stone-900'>
                                    <span>{value}</span>
                                </div>
                                <span className='text-center text-stone-900 capitalize'> {label} </span>
                            </div>
                        
					);
				})}
                </div>
			</div>
		</div>
	);
}

export default Countdown