import React from "react";

// import Sponsor from '../resources/sponsor.jpg'

const Sponsors = () => {
    return( 
        <div className="grid justify-items-center pb-4 my-20 mb-36">
            <div className="relative rounded-full px-3 py-1 mb-4 w-28 text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
            LCTIP XXXII
            </div>
            <h1 className="text-[2rem] sm:text-4xl md:text-5xl text-center md:text-left helvetica font-extrabold text-stone-900 pb-0 md:pb-4 mb-0 md:mb-10">
            SPONSORS
            </h1>
            {/* <img src={Sponsor} className="py-5 w-10/12 md:w-6/12 rounded-lg" alt="..."></img> */}
        </div>
    )
}

export default Sponsors;