import React from 'react'

import Banner from '../components/Banner'
import ChallengeContent from '../components/ChallengeContent'

import Element1 from "../assets/element-1.png"
import Element2 from "../assets/element-2.png"
import Element3 from "../assets/element-3.png"
import Element4 from "../assets/element-4.png"
import Element5 from "../assets/element-5.png"
import Element6 from "../assets/element-6.png"

const ChallengePage = () => {
  return (
    <div className='relative overflow-hidden'>
      <div className="absolute -right-24 -top-4 hover:scale-110 ease-in-out duration-300 ">
          <img src={Element3} alt="..." className="h-48 w-max lg:h-80 lg:w-max"/>
      </div>
      <div className="absolute -left-2 top-12 rotate-180 hover:scale-110 ease-in-out duration-300 ">
          <img src={Element4} alt="..." className="h-48 w-max lg:h-80 lg:w-max"/>
      </div>
      <div className="absolute -left-12 top-[480px] hover:scale-110 ease-in-out duration-300 ">
          <img src={Element5} alt="..." className="h-36 w-max lg:h-64 lg:w-max"/>
      </div>
      <div className="absolute -right-10 top-[560px] hover:scale-110 ease-in-out duration-300 ">
          <img src={Element6} alt="..." className="h-48 w-max lg:h-80 lg:w-max"/>
      </div>
    <Banner title="MYSTERY QUESTSCAPE" />
    <ChallengeContent />
    </div>
  )
}

export default ChallengePage