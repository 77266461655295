import React from "react";

// Components
import Sponsors from "./Sponsors";

const SeminarContent = () => {
    return( 
        <div className="lg:container w-full mx-auto">
            <div className="w-11/12 h-full mx-auto bg-[#FDCF50] rounded-[24px] lg:rounded-[48px] mb-24">
                <div className="container w-11/12 md:w-11/12 mx-auto">
                    
                    {/* About */}
                    <div className="grid grid-row md:grid md:grid-row lg:grid lg:grid-cols-1 py-24 gap-6 md:gap-8 lg:gap-14">
                        <div className="order-1 justify-items-center lg:justify-items-Center">
                            <div className="grid justify-items-center lg:justify-items-center pb-4">
                                <div className="relative rounded-full px-3 py-1 w-24 text-sm leading-6 bg-white text-black">
                                ABOUT
                                </div>
                            </div>
                            <p className="text-[0.65rem] md:text-base text-justify lg:text-center leading-5 md:leading-7 text-stone-800 px-2 lg:px-0 pb-4">
                            <br></br>Seminar utama LCTIP XXXII mengusung tema:<br/>
                            </p>
                            <h1 className="helvetica text-[2rem] sm:text-5xl text-center lg:text-center fredoka font-extrabold text-stone-900 pb-0 md:pb-8">
                            Symphony of Creamy Bliss <br/>as A Trend in Food Industry<br/>
                            </h1>
                            <div className="grid grid-cols-2 justify-items-centerw-10/12">
                                <div className="grid order-1 justify-items-center">
                                    <div className="relative rounded-full px-3 py-4 w-11/12 text-sm leading-6 bg-white text-black hover:scale-110 ease-in-out duration-200">
                                    Unraveling The Process Involved <br/>Behind Creating Delightful Creamy Dishes and Beverages
                                    </div>
                                </div>
                                <div className="grid order-2 justify-items-center">
                                    <div className="relative rounded-full px-3 py-4 w-11/12 text-sm leading-6 bg-white text-black hover:scale-110 ease-in-out duration-200">
                                    Cream as a Luxurious Nutrient Carrier <br/> for Enhancing Taste and Nutrition
                                    </div>
                                </div>
                            </div>
                            <p className="text-[0.65rem] md:text-base text-justify lg:text-center leading-5 md:leading-7 text-stone-800 px-2 lg:px-0">
                            <br></br>Seminar nasional ini diharapkan dapat <span className="font-bold">memberikan wawasan dan pengetahuan bagi para akademisi dan masyarakat umum tentang pangan </span> bersifat creamy dan produk olahannya serta beragam manfaat mengonsumsinya. Melalui seminar nasional ini, kami harap pangan bersifat creamy dapat dioptimalisasikan dalam pengolahannya agar lebih bernutrisi dan dikonsumsi dengan segudang manfaat serta keunggulannya.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Timeline */}
            <div className="bg-[#695497] py-20 my-20 md:my-28">
                <div className="container w-9/12 mx-auto">
                    <div className="grid justify-items-center mb-4">
                        <div className="pb-4">
                            <div className="relative rounded-full px-3 py-1 w-24 text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
                            EVENTS
                            </div>
                        </div>
                        <h1 className="helvetica text-[2rem] sm:text-4xl md:text-5xl lg:text-5xl text-center lg:text-left fredoka font-extrabold text-white">
                        TIMELINE
                        </h1>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        <div className="flex items-center justify-between">
                            <div className="flex mr-3 md:mr-4">
                                <p className="text-[0.6rem] md:text-base text-left text-white">Registrasi</p>
                            </div>
                            <div className="flex flex-auto mx-auto">
                                <p className="border-b border-dotted w-full"></p>
                            </div>
                            <div className="ml-2 md:ml-4">
                                <p className="text-[0.6rem] md:text-base text-right text-white">15 September - 15 Oktober 2024</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex mr-5">
                                <p className="text-[0.6rem] md:text-base text-left text-white">National Seminar</p>
                            </div>
                            <div className="flex flex-auto">
                                <p className="border-b border-dotted w-full"></p>
                            </div>
                            <div className="ml-2 md:ml-4">
                                <p className="text-[0.6rem] md:text-base text-right text-white">2 November 2024</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="container w-10/12 mx-auto">
                <Sponsors/>
            </div>


        </div>
    )
}

export default SeminarContent;