import React from 'react'
import { useEffect } from 'react'

//Components
import Header from '../components/Header'

import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Footer from '../components/Footer'

const RootLayout = () => {
  
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
        <Header/>

        <main>
            <Outlet/>
        </main>

        <Footer />
    </>
  )
}

export default RootLayout;