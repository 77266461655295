import React from 'react'
import EventContent from '../components/EventContent'

const EventPage = () => {
  return (
    <>
    <EventContent />
    </>
  )
}

export default EventPage