import React from 'react';
import './App.css';

//Import Layout
import RootLayout from './layouts/RootLayout';

//Import Main Pages
import MainPage from './pages/MainPage';
import CompetitionPage from './pages/CompetitionPage';
import SeminarPage from './pages/SeminarPage';
import ChallengePage from './pages/ChallengePage';
import EventPage from './pages/EventPage';
import FAQPage from './pages/FAQPage';
import RegistrationPage from './pages/RegistrationPage';
import MaintenancePage from './pages/MaintenancePage';

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/' element={<RootLayout />}>
        <Route path='/' element={<MainPage />} />

        <Route path="events" element={<EventPage />} />
        <Route path='/events/competition' element={<CompetitionPage />} />
        <Route path='/events/seminar' element={<SeminarPage />} />
        <Route path='/events/challenge' element={<ChallengePage />} />

        <Route path='/faq' element={<FAQPage />} />
        <Route path='/registration' element={<MaintenancePage />} />
      </Route>

      <Route path="*" element={<MaintenancePage />} />
    </Route>
  )
);

const App = () => {
  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
};

export default App;
