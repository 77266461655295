import React from "react";
import Button from "./Button";

// Design Asset
import Flower from "../assets/flower.png";
import Mascot1 from "../assets/mascot-1.png";

const Hero = () => {
  return (
    <div className="bg-white w-full pb-32 relative overflow-hidden">
      
      <div className="invisible lg:visible absolute lg:pt-[360px] lg:pl-96 ">
        <img src={Flower} alt="..." className="h-0 w-0 lg:h-24 lg:w-24 lg:animate-spin"/>
      </div>

      <div className="invisible lg:visible absolute lg:pt-[144px] lg:pl-[900px] ">
        <img src={Flower} alt="..." className="object-contain h-0 w-0 lg:h-24 lg:w-24 lg:animate-spin"/>
      </div>

      <div className="invisible lg:visible absolute lg:pt-[360px] lg:pl-[720px] hover:scale-110 ease-in-out duration-300 ">
        <img src={Mascot1} alt="..." className="object-contain h-0 w-0 lg:h-96 lg:w-96"/>
      </div>

      <div className="justify-center items-center">
        <div className="flex mx-auto pt-36 lg:pt-36 order-2 items-center justify-center lg:justify-start lg:pl-64">
            <div className="order-2">
                <div className="mb-8 flex justify-center">
                    <div className="relative rounded-full px-3 py-1 w-36 text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
                    HIMITEPA IPB
                    </div>
                </div>
            </div>
        </div>

        <div className="lg:pb-36">
            <div className="text-center lg:text-left lg:pl-48">
                <h1 className="helvetica font-extrabold text-4xl sm:text-5xl md:text-6xl text-stone-900">
                LOMBA CEPAT TEPAT
                </h1>
            </div>
            <div className="text-center">
                <h1 className="helvetica font-extrabold text-4xl sm:text-5xl md:text-6xl text-stone-900">
                         ILMU PANGAN XXXII
                </h1>
            </div>
        </div>

        <div className="flex-row mx-auto order-2 items-center justify-center">
            <div className="pl-0 text-center lg:pl-24 lg:text-left">
                <p className="mt-6 text-[0.65rem] md:text-lg leading-5 md:leading-8 text-stone-800">
                <span className="invisible lg:visible">-</span> The Biggest Annual{" "}
                <span className="font-bold">
                    Food Science and Technology Competition
                </span>{" "}<br></br>
                for High School Students from All Over Indonesia held by<br/>Himpunan
                Mahasiswa Ilmu dan Teknologi Pangan IPB{" "}
                </p>
            </div>
            <div className="justify-center lg:justify-normal lg:pl-24 flex gap-x-6 pt-6">
                <Button name="Register" link="/registration"/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
