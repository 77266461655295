import React from "react";

// Components
import Sponsors from "./Sponsors";

// Assets
import QR from "../assets/mystery-guideline.png"

// import Pics from "../resources/1.JPG"
// import Pics2 from "../resources/2.JPG"
// import Pics3 from "../resources/3.JPG"
// import Pics4 from "../resources/IMG_2965.JPG";
// import Pics5 from "../resources/IMG_6637.JPG";
// import Pics6 from "../resources/IMG_6642.JPG";
// import Pics7 from "../resources/IMG_6744.JPG";

const ChallengeContent = () => {
    return( 
        <div className="lg:container w-full mx-auto">
            <div className="w-11/12 h-full mx-auto bg-[#FDCF50] rounded-[24px] lg:rounded-[48px] mb-24">
                <div className="container w-11/12 md:w-11/12 mx-auto">
                    
                    {/* About */}
                    <div className="grid grid-row md:grid md:grid-row lg:grid lg:grid-cols-1 py-24 gap-6 md:gap-8 lg:gap-14">
                        <div className="order-1 justify-items-center lg:justify-items-start col-span-2">
                            <div className="grid justify-items-center lg:justify-items-start pb-4">
                                <div className="relative rounded-full px-3 py-1 w-24 text-sm leading-6 bg-white text-black">
                                GUIDELINE
                                </div>
                            </div>
                            <h1 className="helvetica text-[2rem] sm:text-5xl text-center lg:text-left fredoka font-extrabold text-stone-900 pb-0 md:pb-4">
                            APA ITU MISTERY QUESTSCAPE?
                            </h1>
                            <p className="text-[0.65rem] md:text-base text-justify lg:text-left leading-5 md:leading-7 text-stone-800 px-2 lg:px-0">
                            <br></br>Mystery Questscape merupakan permainan berupa tebak kata berbasis emoji yang terdiri atas 3 kategori pertanyaan mengenai nasionalisme. 
                            <br/><br/>Permainan dilaksanakan pada kegiatan Edu - Camp Tour yang terdiri dari 2 sesi, yaitu sebelum peserta memasuki tempat tujuan pertama dan kedua. 
                            <br/><br/>Permainan ini akan dilakukan berkelompok sesuai dengan tim yang berlomba. Teknis dilakukan dengan diberikan pertanyaan sejumlah 12 soal dan jawaban dikumpulkan melalui Google Formulir. Tim dengan poin penilaian tertinggi akan mendapatkan reward. Dengan demikian, semua peserta akan terlibat dalam tantangan yang menyenangkan dan menarik ini!</p>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="container w-9/12 mx-auto">
                <div className="grid justify-items-center my-36">
                    <div className="flex-row justify-center bg-[#FDCF50] rounded-xl py-12 px-12">
                        <div className="text-[0.6rem] sm:text-sm lg:text-md w-full justify-center text-center leading-7 lg:leading-9 pb-6 text-stone-900">
                        Link Pengerjaan: { } 
                                <a href="ipb.link/challenge-lctipxxxii-day1" className="font-bold hover:underline">MISTERY QUESTSCAPE</a>
                        </div>
                        <div className="p-5 w-64 h-64 auto mx-auto rounded-lg justify-center bg-white hover:scale-110 ease-in-out duration-200">
                            <img src={QR} className="" alt="qr-guidebook"></img>

                        </div>
                    </div>
                </div>

                <Sponsors/>
            </div>


        </div>
    )
}

export default ChallengeContent;