import React from "react";

// Components
import PrizeCard from "./PrizeCard";
import Sponsors from "./Sponsors";

//Router
// import { Link } from "react-router-dom";

// Assets
import QR from "../assets/guidebook.png"
import Flyer from "../assets/flyer.jpg"

// import Pics from "../resources/1.JPG"
// import Pics2 from "../resources/2.JPG"
// import Pics3 from "../resources/3.JPG"
// import Pics4 from "../resources/IMG_2965.JPG";
// import Pics5 from "../resources/IMG_6637.JPG";
// import Pics6 from "../resources/IMG_6642.JPG";
// import Pics7 from "../resources/IMG_6744.JPG";

const CompetitionContent = () => {
    return( 
        <div className="container w-full mx-auto">
            <div className="w-11/12 h-full mx-auto bg-[#FDCF50] rounded-[24px] lg:rounded-[48px] mb-24">
                <div className="container w-11/12 md:w-11/12 mx-auto">
                    
                    {/* About */}
                    <div className="grid grid-row md:grid md:grid-row lg:grid lg:grid-cols-1 py-24 gap-6 md:gap-8 lg:gap-14">
                        <div className="order-1 justify-items-center lg:justify-items-start">
                            <div className="grid justify-items-center lg:justify-items-center pb-4">
                                <div className="relative rounded-full px-3 py-1 w-24 text-sm leading-6 bg-white text-black">
                                ABOUT
                                </div>
                            </div>
                            <h1 className="helvetica text-[2rem] sm:text-5xl text-center lg:text-center font-extrabold text-stone-900 pb-0 md:pb-4">
                            LOMBA CEPAT TEPAT<br></br>ILMU PANGAN XXXII
                            </h1>
                            <p className="text-[0.65rem] md:text-base text-justify lg:text-center leading-5 md:leading-7 text-stone-800 px-2 lg:px-0 pb-10">
                            <br/>Kompetisi LCTIP XXXI merupakan lomba cepat tepat terkait ilmu pangan yang terdiri atas beberapa babak ditujukan spesial bagi siswa dan siswi SMA/Sederajat yang memiliki <span className="font-bold">ketertarikan dalam bidang pangan</span>. 
                            <br/><br/>Beberapa mata pelajaran yang diujikan dalam lomba ini adalah Matematika, Fisika, Kimia, Biologi, dan Ilmu Pangan. Tujuan diadakannya lomba ini adalah <span className="font-bold">meningkatkan pengetahuan, wawasan, dan kepedulian siswa SMA terhadap permasalahan pangan di Indonesia</span>.
                            </p>
                            <div className="grid grid-cols-2 justify-items-centerw-10/12">
                                <div className="grid order-1 justify-items-center">
                                    <div className="relative rounded-full px-3 py-4 w-11/12 text-sm leading-6 bg-white text-black hover:scale-110 ease-in-out duration-200">
                                    Biaya Pendaftaran 1: <br/>Rp 75.000,00
                                    </div>
                                </div>
                                <div className="grid order-2 justify-items-center">
                                    <div className="relative rounded-full px-3 py-4 w-11/12 text-sm leading-6 bg-white text-black hover:scale-110 ease-in-out duration-200">
                                    Biaya Pendaftaran 2: <br/>Rp 250.000,00
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rounded-2xl w-11/12 h-full mx-auto">
                <img src={Flyer} alt="..." className="lg:w-full rounded-2xl"/>
            </div>

            {/* Timeline */}
            <div className="bg-[#695497] py-20 my-20 md:my-28">
                <div className="container w-9/12 mx-auto">
                    <div className="grid justify-items-center mb-4">
                        <div className="pb-4">
                            <div className="relative rounded-full px-3 py-1 w-24 text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
                            EVENTS
                            </div>
                        </div>
                        <h1 className="helvetica text-[2rem] sm:text-4xl md:text-5xl lg:text-5xl text-center lg:text-left fredoka font-extrabold text-white">
                        TIMELINE
                        </h1>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        <div className="flex items-center justify-between">
                            <div className="flex mr-3 md:mr-4">
                                <p className="text-[0.6rem] md:text-base text-left text-white">Registrasi I</p>
                            </div>
                            <div className="flex flex-auto mx-auto">
                                <p className="border-b border-dotted w-full"></p>
                            </div>
                            <div className="ml-2 md:ml-4">
                                <p className="text-[0.6rem] md:text-base text-right text-white">1 - 31 Agustus 2024</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex mr-5">
                                <p className="text-[0.6rem] md:text-base text-left text-white">Try Out</p>
                            </div>
                            <div className="flex flex-auto">
                                <p className="border-b border-dotted w-full"></p>
                            </div>
                            <div className="ml-2 md:ml-4">
                                <p className="text-[0.6rem] md:text-base text-right text-white">21 September 2024</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex mr-0 md:mr-4 w-6/12 md:w-auto">
                                <p className="text-[0.6rem] md:text-base text-left text-white">Day 1 (Qualification Stage, Edu & Campus Tour)</p>
                            </div>
                            <div className="flex flex-auto">
                                <p className="border-b border-dotted w-full"></p>
                            </div>
                            <div className="ml-2 md:ml-4">
                                <p className="text-[0.6rem] md:text-base text-right text-white">1 November 2024</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex mr-4">
                                <p className="text-[0.6rem] md:text-base text-left text-white">Registrasi II</p>
                            </div>
                            <div className="flex flex-auto">
                                <p className="border-b border-dotted w-full"></p>
                            </div>
                            <div className="ml-2 md:ml-4">
                                <p className="text-[0.6rem] md:text-base text-right text-white">1 November 2024 ( 18.00 - 23.59 )</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex mr-1 md:mr-4 w-6/12 md:w-auto">
                                <p className="text-[0.6rem] md:text-base text-left text-white">Day 2 (Quarter Final Stage, Seminar Nasional)</p>
                            </div>
                            <div className="flex flex-auto">
                                <p className="border-b border-dotted w-full"></p>
                            </div>
                            <div className="ml-2 md:ml-4">
                                <p className="text-[0.6rem] md:text-base text-right text-white">2 November 2024</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex mr-1 md:mr-4 w-6/12 md:w-auto">
                                <p className="text-[0.6rem] md:text-base text-left text-white">Day 3 (Semi Final Stage, Mix and Make, and Final Stage)</p>
                            </div>
                            <div className="flex flex-auto">
                                <p className="border-b border-dotted w-full"></p>
                            </div>
                            <div className="ml-2 md:ml-4">
                                <p className="text-[0.6rem] md:text-base text-right text-white">3 November 2024</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                
            <div className="container w-10/12 md:w-10/12 mx-auto">
                {/* Prizes */}
                <div className="grid justify-items-center pb-4 my-20">
                    <div className="relative rounded-full px-3 py-1 mb-4 w-28 text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
                    LCTIP XXXI
                    </div>
                    <h1 className="helvetica text-[2rem] sm:text-4xl md:text-5xl text-center md:text-left fredoka font-extrabold text-stone-900 pb-4 mb-10">
                    PRIZES COMPETITION
                    </h1>

                    <div className="grid grid-cols md:grid-cols-2 lg:grid-cols-4 py-4 gap-6">
                        <PrizeCard title="Juara 1" money="Rp 6.000.000" prize="+ Piala + Golden Ticket ITP IPB + Piala bergilir + Sertifikat"/>
                        <PrizeCard title="Juara 2" money="Rp 4.000.000" prize="+ Piala + Sertifikat"/>
                        <PrizeCard title="Juara 3" money="Rp 2.000.000" prize="+ Piala + Sertifikat"/>
                        <PrizeCard title="Juara Harapan" money="Rp 600.000" prize="+ Sertifikat"/>         
                    </div>
                </div>
            </div>
                        
            <div className="container w-10/12 mx-auto">
                <div className="grid justify-items-center pb-4 my-2">
                    <div className="relative rounded-full px-3 py-1 mb-4 w-36 text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
                    COMPETITION
                    </div>
                    <h1 className="helvetica text-[2rem] sm:text-4xl md:text-5xl text-center md:text-left fredoka font-extrabold text-stone-900 pb-4">
                        SILABUS
                    </h1>
                </div>

                <p className="text-[0.6rem] sm:text-sm lg:text-base text-justify leading-7 lg:leading-9 text-stone-800">
                <span className="font-bold">Matematika</span><br></br>Bangun datar dan ruang, trigonometri, peluang, kombinatorika, logika matematika, statistika, fungsi, diferensial dan integral, persamaan garis dan persamaan kuadrat, aljabar, barisan dan deret, eksponensial dan logaritma, limit, dan matematika terapan.
                <br></br><br></br><span className="font-bold">Fisika</span><br></br>Kinematika, energetika, fluida, sifat fisika bahan, listrik, getaran dan gelombang, termodinamika, fisika modern, fisika atom, dan fisika optik.
                <br></br><br></br><span className="font-bold">Kimia</span><br></br>Stoikiometri larutan, polimer, hukum gas ideal, reaksi redoks, kesetimbangan kimia, koloid dan sifat koligatif larutan, kelat, senyawa kompleks, prinsip asam basa & larutan buffer, elektrokimia, kimia organik.
                <br></br><br></br><span className="font-bold">Biologi</span><br></br>Mikrobiologi, biologi sel, virologi, fotosintesis, metabolisme tubuh, pencernaan, sistem transportasi tubuh, sistem kekebalan tubuh, fisiologis tubuh dan enzim, anatomi, bioteknologi, biologi lingkungan, dan genetika.
                <br></br><br></br><span className="font-bold">Ilmu pangan</span><br></br>Komponen makro dan mikro, analisis pangan, karakteristik bahan pangan, bahan tambahan pangan, flavor dan kandungan dalam pangan, sanitasi pangan, fermentasi, mikrobiologi pangan, biokimia pangan, metabolisme komponen pangan, teknik pangan, rekayasa proses pangan, alat-alat pengolahan, evaluasi sensori.
                </p>   

                <div className="grid justify-items-center pb-4 my-2 pt-12">
                    <div className="relative rounded-full px-3 py-1 mb-4 w-36 text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
                    COMPETITION
                    </div>
                    <h1 className="helvetica text-[2rem] sm:text-4xl md:text-5xl text-center md:text-left fredoka font-extrabold text-stone-900 pb-4">
                        REFERENCE
                    </h1>
                </div>
                
                <p className="text-[0.6rem] sm:text-sm lg:text-base text-justify leading-7 text-stone-800 border rounded-lg px-12 py-12 flex flex-col lg:flex-none gap-5 lg:gap-4">
                    <div className="flex">
                    <li></li>Buku Pendamping Mata Pelajaran Matematika, Fisika, Kimia, dan Biologi SMA/sederajat.
                    </div>
                    <div className="flex">
                    <li></li>Muchtadi TR, Sugiyono, Ayustaningwarno F. 2013. Ilmu Pengetahuan Bahan Pangan. Bandung(ID): Alfabeta.
                    </div>
                    <div className="flex">
                    <li></li>Fellows PJ. 2016. Teknologi Pengolahan Pangan: Prinsip dan Praktik. Jakarta (ID): EGC.
                    </div>
                    <div className="flex">
                    <li></li>Kusnandar F. 2019. Kimia Pangan Komponen Makro. Jakarta (ID): PT Bumi Aksara.
                    </div>
                    <div className="flex">
                    <li></li>Rahayu WP, Nurwitri CC, Komalasari P. 2012. Mikrobiologi Pangan. Bogor (ID): IPB Press 
                    </div>
                    <div className="flex">
                    <li></li>PATPI, Kusnandar F, Rahayu WP, Marpaung AM, Santoso U. 2020. Perspektif Global Ilmu dan Teknologi Pangan. Bogor (ID): IPB Press.
                    </div>
                    <div className="flex">
                    <li></li>Anggraeni NI. 2018. Biokimia Berorientasi pada Analisis Pangan Fungsional. Bandung (ID): Bitread Publishing.
                    </div>
                    <div className="flex">
                    <li></li>Wijaya CH, Mulyono N. 2010. Bahan Tambahan Pangan: Pemanis. Bogor (ID): IPB Press.
                    </div>
                    <div className="flex">
                    <li></li>Wijaya CH, Mulyono N, Afandi FA. 2011. Bahan Tambahan Pangan: Pengawet. Bogor (ID): IPB Press.
                    </div>
                    <div className="flex">
                    <li></li>Andarwulan N, Kusnandar F, Herawati D. 2011. Analisis Pangan. Jakarta (ID): PT Dian Rakyat.
                    </div>
                </p>    
            </div>

            <div className="container w-full mx-auto my-36">
                <div className="w-fit mx-auto flex-row items-center justify-center bg-[#FDCF50] rounded-xl py-8 px-8">
                    <div className="text-[0.6rem] sm:text-sm lg:text-md text-center leading-7 pb-4 lg:leading-7 text-stone-900">
                        Informasi lebih lanjut terdapat di Guidebook LCTIP XXXII <br></br>yang dapat diakses pada link berikut: { } 
                        <a href="https://drive.google.com/drive/folders/1byrv2F3CmkDQOzHoe_suf_bObgWhqMZa" className="font-bold hover:underline">GUIDEBOOK</a>
                    </div>

                    <div className="p-5 w-64 h-64 auto mx-auto rounded-lg flex items-center bg-white hover:scale-110 ease-in-out duration-200">
                        <img src={QR} className="" alt="qr-guidebook"></img>

                    </div>
                </div>
                <Sponsors/>
            </div>


        </div>
    )
}

export default CompetitionContent;