import React from 'react'

import Banner from '../components/Banner'
import FAQContent from '../components/FAQContent'

const FAQPage = () => {
  return (
    <>
    <Banner title="FAQ'S" />
    <FAQContent />
    </>
  )
}

export default FAQPage