import React from 'react'

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Hero from '../components/Hero'
import Content from '../components/Content'

import Mascot1 from "../assets/mascot-1.png";
import Mascot2 from "../assets/mascot-2.png";
import Mascot3 from "../assets/mascot-3.png";
import Mascot4 from "../assets/mascot-4.png";
import Mascot5 from "../assets/mascot-5.png";

const MainPage = () => {

  const [isSuccess, setIsSuccess] = useState('');
  const [link, setLink] = useState('');
  const {state} = useLocation();

  useEffect(() => {
    handleRegistration();
    }, [])

    const handleRegistration = () => {
      if(state !== null){
        setIsSuccess(state.message)
        setLink(state.link)
      }
    }

  return (
    <div className='relative overflow-hidden'>

      {/* <Announcement /> */}
      <div className="items-center">
        {
          isSuccess && 
            <div className="relative">        
                <div className="fixed inset-x-0 mx-auto top-[120px] w-10/12 rounded-lg bg-[#499e86] text-white px-4 py-3 z-10" role="alert">
                    <p className="block sm:inline">
                        { isSuccess } <a href={ link } className="font-bold hover:underline">GRUP WHATSAPP</a>
                    </p>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg
                        onClick={() => setIsSuccess(null)}
                        className="fill-current h-6 w-6 text-white"
                        role="button"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        >
                        <title>Close</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                    </span>
                </div>
            </div>
          } 
        </div>

        {/* Hiasan */}
        <div className="absolute -right-28 top-[1280px] hover:scale-110 ease-in-out duration-300">
            <img src={Mascot2} alt="..." className="h-0 w-max lg:h-96 lg:w-max -rotate-[45deg] "/>
        </div>
        <div className="absolute -left-32 top-[2600px] hover:scale-110 ease-in-out duration-300">
            <img src={Mascot4} alt="..." className="h-0 w-max lg:h-96 lg:w-max rotate-[45deg] "/>
        </div>
        <div className="absolute -right-28 top-[3200px] hover:scale-110 ease-in-out duration-300">
            <img src={Mascot3} alt="..." className="h-0 w-max lg:h-96 lg:w-max -rotate-[20deg] "/>
        </div>
        <Hero />
        <Content />
    </div>
  )
}

export default MainPage