import React from 'react'
import MaintenanceContent from '../components/MaintenanceContent'

const MaintenancePage = () => {
  return (
    <div>
        <MaintenanceContent />
    </div>
  )
}

export default MaintenancePage